import { $get } from '@js/request'
import { RemunerationSchema } from '@components/Schema/RemunerationSchema'

export async function getListRequest (id: string, t?: string, begin?: number, end?: number): Promise<RemunerationSchema[]> {
  const condition: Object[] = []
  if (typeof t === 'string' && t.length > 0) {
    condition.push({ k: 'caseno_desc', o: 'like', v: `%${t}%` })
  }
  if (typeof begin === 'number') {
    condition.push({ k: 'r.pay_datetime', o: '>=', v: begin })
  }
  if (typeof end === 'number') {
    condition.push({ k: 'r.pay_datetime', o: '<=', v: end })
  }
  const data: { [k: string]: any } = {
    current: 1,
    page_size: 10000,
    arbitrator_accountid: id,
    condition: JSON.stringify(condition)
  }
  const res = await $get('/api/xapc/remuneration/list', data)
  return res.data.list
}
