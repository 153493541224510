import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import { Dropdown, Avatar, Menu, message } from 'antd'
import { History, Location } from 'history'
import { UserOutlined, PoweroffOutlined } from '@ant-design/icons'
import { NavLink, Switch, Route, Redirect } from 'react-router-dom'
import { BASIC_STATE } from '@redux/reducers/basic'
import { USERINFO, setToken, setUserInfo } from '@redux/actions/basic'
import { companyName, isMobile } from '@js/config'
import style from './Index.module.less'
import logoImg from '@imgs/logo.png'
import CaseList from './pages/CaseList/Index'
import CaseDetail from './pages/CaseDetail/Index'
import OpenList from './pages/OpenList/Index'
import UserInfo from './pages/UserInfo/Index'
import Remuneration from './pages/Remuneration/Index'
import MeetingList from './pages/MeetingList/Index'
import MeetDetail from './pages/MeetDetail/Index'
import SignStatus from './pages/MeetDetail/SignStatus'
import { logoutRequest } from './ajax'
import { awaitWrap } from '@src/assets/js/tool'
import classNames from 'classnames'

interface Props {
  userInfo: USERINFO
  history: History
  location: Location
  setToken: (v: string) => void
  setUserInfo: (v: USERINFO) => void
}

// 退出登录
async function logout (history: History, setToken: (v: string) => void, setUserInfo: (v: USERINFO) => void): Promise<void> {
  const hide = message.loading('退出中', 120)
  const [e] = await awaitWrap(logoutRequest())
  hide()
  if (e !== null) {
    return undefined
  }
  setToken('')
  setUserInfo({
    app_default: '',
    desktop: '',
    employeeid: '',
    id: '',
    is_auth: -1,
    mobile: '',
    realname: '',
    role_default: ''
  })
  history.push('/login')
}

// 顶部头像菜单
function renderMenu (history: History, setToken: (v: string) => void, setUserInfo: (v: USERINFO) => void): ReactElement {
  return (
    <Menu>
      <Menu.Item key={1} icon={<PoweroffOutlined className={style.error} />} onClick={async () => { await logout(history, setToken, setUserInfo) }}><span className={style.logout}>退出</span></Menu.Item>
    </Menu>
  )
}

function Main ({ userInfo, history, location, setToken, setUserInfo }: Props): ReactElement {
  return (
    <div className={classNames(style.container, { [style.mobile]: isMobile })}>
      <div className={style.header}>
        <div className={style['header-left']}>
          <span className={style['header-title']}>
            <img src={logoImg} />
            <span>{companyName}</span>
          </span>
        </div>

        <div className={style['header-menu']}>
          <NavLink activeClassName={style.active} to='/admin/case-list'>案件列表</NavLink>
          <NavLink activeClassName={style.active} to='/admin/open-list'>开庭时间</NavLink>
          <NavLink activeClassName={style.active} to='/admin/remuneration'>仲裁员酬金</NavLink>
          <NavLink activeClassName={style.active} to='/admin/meeting-list'>视频庭审</NavLink>
          <NavLink activeClassName={style.active} to='/admin/user-info'>个人信息</NavLink>
        </div>

        <Dropdown overlay={renderMenu(history, setToken, setUserInfo)} trigger={['click']}>
          <span className={style['header-right']}>
            <span className={style['header-person__name']}>{userInfo.realname}</span>
            <Avatar icon={<UserOutlined />} />
          </span>
        </Dropdown>
      </div>
      <div className={style.body} id='body'>
        <Switch location={location}>
          <Route exact path='/admin/case-list' component={CaseList} />
          <Route path='/admin/case-detail/:caseid' component={CaseDetail} />
          <Route exact path='/admin/meeting-list' component={MeetingList} />
          <Route exact path='/admin/meeting-detail/:id' component={MeetDetail} />
          <Route exact path='/admin/meeting-detail/signature/:id/:personid/:taskid' component={SignStatus} />
          <Route exact path='/admin/open-list' component={OpenList} />
          <Route exact path='/admin/remuneration' component={Remuneration} />
          <Route path='/admin/user-info' component={UserInfo} />
          <Route exact path='/admin*' component={() => <Redirect to='/admin/case-list' />} />
        </Switch>
      </div>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {
    setToken: (v: string) => dispatch(setToken(v)),
    setUserInfo: (v: USERINFO) => dispatch(setUserInfo(v))
  }
})(Main)
