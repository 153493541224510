import { $get } from '@js/request'
import { CountBookSchema } from '@components/Schema/CountBookSchema'

export async function getList (): Promise<CountBookSchema[]> {
  const res = await $get('/api/arbitrator/hearingroom/getArbitratorBooking')
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
