import React, { ReactElement, useState, useEffect } from 'react'
import { ClockCircleOutlined } from '@ant-design/icons'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import style from './Index.module.less'
import { filterEmptyText, formatDate, isEmptyString } from '@js/tool'
import classNames from 'classnames'

interface Props {
  data: CaseSchema
}

// 初始化仲裁员列表
function initArbitrators (d: CaseSchema): string {
  const r = []
  if (!isEmptyString(d.arbitrator)) {
    r.push(d.arbitrator)
  }
  if (!isEmptyString(d.arbitrator1)) {
    r.push(d.arbitrator1)
  }
  if (!isEmptyString(d.arbitrator2)) {
    r.push(d.arbitrator2)
  }
  return r.join('、')
}

// 格式化案件状态和样式
function formatStatus (status: string): { name: string, status: string } {
  const name = [style.status]
  const auditList = ['已提交', '待审核']
  const sendingStatus = ['交费', '立案', '移交送达', '送达完成']
  const doing = ['移交办案', '已组庭', '已结案']
  const close = ['已结案送达', '已归档']
  if (auditList.includes(status)) {
    name.push(style.pass)
    status = '待审核'
  } else if (status === '收案') {
    name.push(style.wait)
    status = '已受理'
  } else if (sendingStatus.includes(status)) {
    name.push(style.wait)
    status = '送达中'
  } else if (doing.includes(status)) {
    name.push(style.wait)
    status = '审理中'
  } else if (close.includes(status)) {
    name.push(style.close)
    status = '已结案'
  } else if (status === '不予受理') {
    name.push(style.close)
    status = '不予受理'
  } else {
    name.push(style.unpass)
    status = '未提交'
  }
  return {
    status: status !== '' ? status : '未提交',
    name: name.join(' ')
  }
}

function Main ({ data }: Props): ReactElement {
  const [applicantName, setApplicantName] = useState('')
  const [respondentName, setRespondentName] = useState('')
  const [arbitrators, setArbitrators] = useState('')
  const [status, setStatus] = useState('')
  const [statusNames, setStatusNames] = useState('')
  const [isFixed, setIsFixed] = useState(window.document.documentElement.offsetWidth >= 1280)
  useEffect(() => {
    if (data !== null) {
      const al = data.applicants
      if (al instanceof Array) {
        setApplicantName(al.map(li => li.name).join('、'))
      }
      const rl = data.respondents
      if (rl instanceof Array) {
        setRespondentName(rl.map(li => li.name).join('、'))
      }
      setArbitrators(initArbitrators(data))
      const o = formatStatus(data.status)
      setStatus(o.status)
      setStatusNames(o.name)
    }
  }, [data])
  useEffect(() => {
    const fn = function (): void {
      setIsFixed(window.document.documentElement.offsetWidth >= 1280)
    }
    window.addEventListener('resize', fn)
    return () => {
      window.removeEventListener('resize', fn)
    }
  }, [])
  return (
    <div className={classNames(style.container, { [style.fixed]: isFixed })}>
      <div className={style.caseno}>案号：{filterEmptyText(data.caseno_desc)}</div>
      <div className={style.line}>
        <span className={style.label}>申请人</span>
        <span>{filterEmptyText(applicantName)}</span>
      </div>
      <div className={style.line}>
        <span className={style.label}>被申请人</span>
        <span>{filterEmptyText(respondentName)}</span>
      </div>
      <div className={style.line}>
        <span className={style.label}>申请日期</span>
        <span>{formatDate(data.submit_datetime)}</span>
      </div>
      <div className={style.line}>
        <span className={style.label}>案件状态</span>
        <span className={statusNames}>
          <ClockCircleOutlined className={style['status-icon']} />
          <span>{status}</span>
        </span>
      </div>
      <div className={style.line}>
        <span className={style.label}>经办秘书</span>
        <span>{filterEmptyText(data.processer)}{!isEmptyString(data.processer_tel) && `（${data.processer_tel}）`}</span>
      </div>
      <div className={style.line}>
        <span className={style.label}>仲裁员</span>
        <span>{filterEmptyText(arbitrators)}</span>
      </div>
    </div>
  )
}

export default Main
