import { $get } from '@js/request'
import { CaseSchema } from '@components/Schema/CaseSchema'
import { formatCaseDataLicense } from '@src/assets/js/tool'

// 获取案件列表
export async function getCaseListRequest (status: string, search: string): Promise<CaseSchema[]> {
  const condition = []
  const doing = ['移交办案', '已组庭', '已结案']
  const closed = ['已结案送达', '已归档']
  if (status === '审理中') {
    condition.push({ k: 'status', o: 'in', v: doing.join() })
  } else if (status === '已结案') {
    condition.push({ k: 'status', o: 'in', v: closed.join() })
  }
  if (search !== '') {
    condition.push({ k: 'processer|applicant_name|respondent_name|caseno_desc|arbitrators', o: 'like', v: `%${search}%` })
  }
  const res = await $get('/api/arbitrator/case', {
    page_size: 1000,
    current: 1,
    condition: JSON.stringify(condition)
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  formatCaseDataLicense(res.data.list)
  return res.data.list.map((li: CaseSchema, i: number) => {
    li.order = i + 1
    return li
  })
}
