import { $get } from '@js/request'
import { FileSchema } from '@components/Schema/FileSchema'

export async function getDocmentUrl (caseid: string, t: string): Promise<FileSchema> {
  const res = await $get('/api/arbitrator/file/getDocByBizType', {
    closure_type: t,
    caseid,
    biztype: '结案文书'
  })
  return res.data
}
