import { ReactElement } from 'react'
import style from './Index.module.less'
import Item, { ItemSchema } from '../Item/Index'
import { List } from 'antd'

interface Props {
  year: string
  month: string
  loading: boolean
  data: ItemSchema[]
}

function Main ({ loading, year, month, data }: Props): ReactElement {
  if (loading) {
    return <span />
  }
  return (
    <div>
      <div className={style['header-block']}>
        <div className={style['header-title']}>{`${year}年${month}月`}</div>
        <div className={style['header-sub-title']}>下月开庭</div>
      </div>
      <List
        className={style.list}
        loading={loading}
        grid={{ gutter: 24, column: 2 }}
        dataSource={data}
        renderItem={(item, i) => <Item data={item} key={i} isNext={false} />}
      />
    </div>
  )
}

export default Main
