import { ReactElement, useState, useEffect } from 'react'
import { Row, Col, DatePicker, Input, List } from 'antd'
import style from './Index.module.less'
import { SearchOutlined } from '@ant-design/icons'
import { getListRequest } from './ajax'
import moment, { Moment } from 'moment'
import { RangeValue } from 'rc-picker/lib/interface'
import { awaitWrap, formatDate } from '@src/assets/js/tool'
import { MonthRemuneration, RemunerationSchema } from '@components/Schema/RemunerationSchema'
import RemunerationItem from './components/Item/Index'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { BASIC_STATE } from '@redux/reducers/basic'

const { RangePicker } = DatePicker

async function initData (id: string, date: RangeValue<Moment>, caseno: string, setDataSource: (v: MonthRemuneration[]) => void, setLoading: (v: boolean) => void): Promise<void> {
  setLoading(true)
  const { t, begin, end } = formatData(date, caseno)
  const [e, d] = await awaitWrap(getListRequest(id, t, begin, end))
  setLoading(false)
  if (e === null && d !== null) {
    setDataSource(formatMonthData(d))
  }
}

function formatMonthData (arr: RemunerationSchema[]): MonthRemuneration[] {
  const list: MonthRemuneration[] = []
  const o: { [k: string]: MonthRemuneration } = { }

  arr.forEach(li => {
    const date = formatDate(li.pay_datetime, 'YYYY年MM月')
    if (o[date] === undefined) {
      o[date] = {
        date,
        amount: 0,
        list: []
      }
    }
    const data: MonthRemuneration = o[date]
    const amount: number = data.amount
    if (typeof li.real_amount_withouttax === 'number') {
      data.amount = amount + li.real_amount_withouttax
    }
    data.list.push(li)
  })

  const keys = Object.keys(o)
  keys.forEach(k => {
    list.push(o[k])
  })
  return list
}

function formatData (date: RangeValue<Moment>, caseno: string): { begin?: number, end?: number, t?: string } {
  const res: { begin?: number, end?: number, t?: string } = {}
  if (typeof caseno === 'string' && caseno.length > 0) {
    res.t = caseno
  }
  if (date !== null && date.length > 0) {
    const b: Moment = date[0] as Moment
    const e: Moment = date[1] as Moment
    res.begin = Math.floor(moment(`${b.format('YYYY-MM-DD')} 00:00:00`).valueOf() / 1000)
    res.end = Math.floor(moment(`${e.format('YYYY-MM-DD')} 23:59:59`).valueOf() / 1000)
  }
  return res
}

function Main (): ReactElement {
  const [date, setDate] = useState<RangeValue<Moment>>(null)
  const [caseno, setCaseno] = useState('')
  const [dataSource, setDataSource] = useState<MonthRemuneration[]>([])
  const [loading, setLoading] = useState(false)
  const setCasenoDebounce = _.debounce(setCaseno, 500)
  const userInfo = useSelector((s: { basic: BASIC_STATE }) => s.basic.userInfo)
  useEffect(() => {
    initData(userInfo.id, date, caseno, setDataSource, setLoading).catch(e => console.error(e))
  }, [date, caseno])
  return (
    <div className={style.container}>
      <Row gutter={24}>
        <Col span={10}>
          <RangePicker className={style.date} size='large' onChange={e => setDate(e)} />
        </Col>
        <Col span={14}>
          <Input className={style.input} size='large' placeholder='搜索案号' addonBefore={<SearchOutlined />} onChange={e => setCasenoDebounce(e.target.value)} />
        </Col>
      </Row>
      <div className={style.body}>
        <List loading={loading} dataSource={dataSource} renderItem={(li, i) => <RemunerationItem key={i} data={li} />} />
      </div>
    </div>
  )
}

export default Main
