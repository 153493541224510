import { ReactElement } from 'react'
import { TaskSchema, FeedbackSchema } from '@src/components/Schema/TaskSchema'
import Header from '../../../Header/Index'
import icon from '@imgs/accord-icon.png'
import style from './Index.module.less'
import FileList from '@components/FileList/Index'

interface Props {
  data: TaskSchema | FeedbackSchema
}

function Main ({ data }: Props): ReactElement {
  return (
    <div className={style.container}>
      <Header icon={icon} title='提交结案文书' />
      <div className={style.line}>
        <div className={style.label}>发起人</div>
        <div className={style.val}>{data.operator}</div>
      </div>
      <div className={style.line}>
        <div className={style.label}>结案文书</div>
        <div className={style.val}><FileList value={data.file} /></div>
      </div>
      <div className={style.line}>
        <div className={style.label}>备注</div>
        <div className={style.val}>{data.content}</div>
      </div>
    </div>
  )
}

export default Main
