import React, { ReactElement, useState, useContext, useEffect, useRef } from 'react'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import { Button, List, Row, Col, Form, FormInstance, Space, message } from 'antd'
import style from './Index.module.less'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Context } from '../../context'
import { TaskSchema, FeedbackSchema } from '@components/Schema/TaskSchema'
import { BASIC_STATE } from '@redux/reducers/basic'
import Dictionary, { DictionaryItem } from '@js/dictionary'
import { DownloadOutlined } from '@ant-design/icons'
import ListItem from './components/Item/Index'
import { awaitWrap } from '@src/assets/js/tool'
import { getDocmentUrl } from './ajax'

function initList (task: TaskSchema | null, setList: (v: Array<TaskSchema | FeedbackSchema>) => void): void {
  let res: Array<TaskSchema | FeedbackSchema> = []
  if (task !== null) {
    res = [task]
    if (task.feedback instanceof Array && task.feedback.length > 0) {
      res = res.concat(task.feedback)
    }
  }
  setList(res)
}

async function getFileHandle (data: { [k: string]: any }, caseid: string): Promise<void> {
  const hide = message.loading('文书生成中', 60)
  const [e, d] = await awaitWrap(getDocmentUrl(caseid))
  hide()
  if (e === null && d !== null) {
    window.open(d.docx_path)
  }
}

interface Props {
  data: CaseSchema
}

function Main ({ data }: Props): ReactElement {
  const c = useContext(Context)
  const formRef = useRef<FormInstance>(null)
  const [listData, setListData] = useState<Array<TaskSchema | FeedbackSchema>>([])
  const [creatable, setCreatable] = useState(false)
  const [operatable, setOperatable] = useState(false)
  const [typeList, setTypeList] = useState<DictionaryItem[]>([])
  const [caseIsClosure, setCaseIsClosure] = useState(false)
  const h = useHistory()
  const userInfo = useSelector((s: { basic: BASIC_STATE }) => {
    return s.basic.userInfo
  })
  useEffect(() => {
    const d = new Dictionary()
    d.init('庭审笔录')
      .then(() => {
        const l = d.getList('庭审笔录')
        setTypeList(l)
      })
      .catch(e => console.error(e))
  }, [])
  useEffect(() => {
    setCaseIsClosure(data !== null && ['已结案', '已结案送达', '已归档'].includes(data.status))
  }, [data])
  useEffect(() => {
    const f = formRef.current
    if (typeList.length > 0 && f !== null) {
      f.setFieldsValue({ type: typeList[0].value })
    }
  }, [formRef, typeList])
  useEffect(() => {
    initList(c.recordTask, setListData)
  }, [c])
  useEffect(() => {
    setCreatable(c.recordSubmitids.includes(userInfo.id) && listData.length === 0)
  }, [listData, userInfo, c.recordSubmitids])
  useEffect(() => {
    setOperatable(c.recordOperatorids.includes(userInfo.id) && listData.length > 0)
  }, [listData, userInfo, c.recordOperatorids])
  return (
    <div id='arbitrator_bilu'>
      <div className={style.header}>庭审笔录</div>
      <List
        dataSource={listData}
        renderItem={(item, i) => {
          return <ListItem key={i} data={item} />
        }}
      />
      {
        !caseIsClosure && (
          <div className={style['type-line']}>
            <Form ref={formRef} onFinish={async d => await getFileHandle(d, data.id)}>
              <Row gutter={24}>
                <Col flex='auto'>
                </Col>
                <Col flex='280px'>
                  <Space size='middle'>
                    <Button htmlType='submit' icon={<DownloadOutlined />}>文书模板下载</Button>
                    {creatable && <Button htmlType='button' type='primary' onClick={() => h.push(`/admin/case-detail/${data.id}/record/create`)}>提交笔录</Button>}
                    {operatable && <Button htmlType='button' type='primary' onClick={() => h.push(`/admin/case-detail/${data.id}/record`)}>重新提交</Button>}
                  </Space>
                </Col>
              </Row>
            </Form>
          </div>
        )
      }
    </div>
  )
}

export default Main
