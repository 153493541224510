import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import backIcon from '@imgs/back-arrow.png'
import logo from '@imgs/logo.png'
import style from './Index.module.less'
import Person from '../../components/Person/Index'
import Claim from '../../components/Claim/Index'
import Accord from '../../components/Accord/Index'
import Evidence from '../../components/Evidence/Index'
import OperateBlock from '../../components/OperateBlock/Index'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import { OperateItem } from '../../ajax'
interface Props {
  caseDetail: CaseSchema
  used: OperateItem[]
}

function Main ({ caseDetail, used }: Props): ReactElement {
  const history = useHistory()
  const claims = caseDetail.claims instanceof Array ? caseDetail.claims.filter(li => li.status === 1) : []
  const cclaims = caseDetail.cclaims instanceof Array ? caseDetail.cclaims.filter(li => li.status === 1) : []
  return (
    <div className={style.container} id='info'>
      <div className={style.header}>
        <span className={style['header-icon']} onClick={() => history.push('/admin/case-list')}>
          <img src={backIcon} />
        </span>
        <span className={style['header-title']}>
          <img className={style.logo} src={logo} />
          <span className={style['header-title-text']}>{caseDetail.caseno_desc}</span>
        </span>
      </div>
      <Person title='申请人' data={caseDetail.applicants} />
      <Person title='申请人代理人' data={caseDetail.applicant_agents} />
      <Person title='被申请人' data={caseDetail.respondents} isRespondent />
      <Person title='被申请人代理人' data={caseDetail.respondents_agents} isRespondent />
      <Accord data={caseDetail} />
      {
        claims.map(li => {
          return <Claim data={li} key={li.id} time />
        })
      }
      {
        cclaims.map(li => {
          return <Claim data={li} key={li.id} anti time />
        })
      }
      <Evidence data={caseDetail.evidence} />
      <OperateBlock data={caseDetail} list={used} />
    </div>
  )
}

export default Main
