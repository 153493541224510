import { $get } from './request'
import { awaitWrap } from './tool'
import { message } from 'antd'

export interface DictionaryGroup {
  list: DictionaryItem[]
  type: string
}

export interface DictionaryItem {
  title: string
  value: string
}

export interface DictionaryMap {
  [p: string]: DictionaryItem[]
}

// 获取字典请求
async function getDictionaryRequest (types: string): Promise<DictionaryMap> {
  const res = await $get('/api/common/dictionary', {
    types
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return formatDictionaries(res.data)
}

// 获取字典
async function getDictionary (types: string, setDictionary: (v: DictionaryMap) => void): Promise<void> {
  const [e, d] = await awaitWrap(getDictionaryRequest(types))
  if (e !== null) {
    return undefined
  }
  if (d === null) {
    return await message.error('字典数据未返回')
  }
  setDictionary(d)
}

// 格式化枚举字典
function formatDictionaries (dictionaries: DictionaryGroup[] = []): DictionaryMap {
  const result: DictionaryMap = {}
  dictionaries.forEach(d => {
    result[d.type] = d.list
  })
  return result
}

export default class Dictionary {
  store: DictionaryMap = {}
  async init (types: string): Promise<void> {
    await getDictionary(types, this.setStore.bind(this))
  }

  setStore (store: DictionaryMap): void {
    this.store = store
  }

  getStore (): DictionaryMap {
    return this.store
  }

  getList (type: string): DictionaryItem[] {
    if (Object.prototype.hasOwnProperty.call(this.store, type)) {
      return this.store[type]
    }
    return []
  }
}
