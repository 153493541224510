import React, { ReactElement } from 'react'
import { Radio, Input, Space } from 'antd'
import style from './Index.module.less'

const { Search } = Input

type Fn = (v: string) => void

interface Props {
  value: string
  onChange: Fn
  length: number
  onSearch: Fn
}

function Main ({ value, onChange, length, onSearch }: Props): ReactElement {
  return (
    <div className={style.header}>
      <span className={style['header-left']}>
        <span className={style['header-title']}>我的案件</span>
        <span className={style['header-sub-title']}>{length}</span>
      </span>
      <span className={style['header-right']}>
        <Space>
          <Search placeholder="请输入案号、仲裁员、当事人或秘书" onSearch={(v) => onSearch(v)} style={{ width: 300 }} allowClear />
          <Radio.Group value={value} onChange={v => onChange(v.target.value)}>
            <Radio.Button value='全部'>全部</Radio.Button>
            <Radio.Button value='审理中'>审理中</Radio.Button>
            <Radio.Button value='已结案'>已结案</Radio.Button>
          </Radio.Group>
        </Space>
      </span>
    </div>
  )
}

export default Main
