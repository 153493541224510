import { $get, $put } from '@js/request'
import { ArbitratorSchema } from '@components/Schema/ArbitratorInfo'
import { compressLicense, decompressLicense } from '@src/assets/js/tool'

export async function updateRequest (id: string, d: { [k: string]: any }): Promise<void> {
  compressLicense(d)
  const res = await $put(`/api/arbitrator/me/${id}`, d)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
}

export async function getRequest (id: string): Promise<ArbitratorSchema> {
  const res = await $get(`/api/arbitrator/me/${id}`)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  decompressLicense(res.data)
  return res.data
}
