import { Action } from 'redux'
import BASIC from '../actionTypes/basic'
import { ArbitratorSchema } from '@components/Schema/ArbitratorInfo'

export interface USERINFO {
  app_default: string
  desktop: string
  employeeid: string
  id: string
  is_auth: number
  mobile: string
  realname: string
  role_default: string
}

export interface SET_TOKEN_ACTION extends Action {
  type: BASIC
  data: NonNullable<string>
}

export interface SET_USERINFO_ACTION extends Action {
  type: BASIC
  data: NonNullable<USERINFO>
}

export interface SET_SMS_SEND_TIME extends Action {
  type: BASIC
  data: number
}

export interface SET_ARBITRATOR_INFO extends Action {
  type: BASIC
  data: ArbitratorSchema
}

export type BASIC_ACTION = SET_TOKEN_ACTION | SET_USERINFO_ACTION | SET_SMS_SEND_TIME

export const setToken = (data: NonNullable<string>): SET_TOKEN_ACTION => ({
  type: BASIC.SET_TOKEN,
  data
})

export const setUserInfo = (data: NonNullable<USERINFO>): SET_USERINFO_ACTION => ({
  type: BASIC.SET_USER_INFO,
  data
})

export const setSMSSendTime = (data: number): SET_SMS_SEND_TIME => ({
  type: BASIC.SET_SMS_SEND_TIME,
  data
})

export const setArbitratorInfo = (data: ArbitratorSchema): SET_ARBITRATOR_INFO => ({
  type: BASIC.SET_ARBITRATOR_INFO,
  data
})
