import React, { ReactElement, useState, useEffect } from 'react'
import { match, Switch, Route, Redirect } from 'react-router-dom'
import { History, Location } from 'history'
import { Skeleton, message, BackTop } from 'antd'
import { ToTopOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { USERINFO } from '@src/redux/actions/basic'
import { BASIC_STATE } from '@src/redux/reducers/basic'
import style from './Index.module.less'
import BerifInfo from './components/BerifInfo/Index'
import Operate from './components/Operate/Index'
import Info from './pages/Info/Index'
import ClosureDocument from './pages/ClosureDocument/Index'
import ClosureDocumentCreate from './pages/ClosureDocumentCreate/Index'
import Record from './pages/Record/Index'
import RecordCreate from './pages/RecordCreate/Index'
import { getCaseDetailRequest, getOperateRequest, OperateItem, addOperateUsedRequest, initTaskByTypeRequest } from './ajax'
import { awaitWrap } from '@js/tool'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import { Context } from './context'
import { TribunalPartySchema } from '@src/components/Schema/TribunalPartySchema'
import { TaskSchema } from '@components/Schema/TaskSchema'

interface Params {
  caseid: string
}

interface Props {
  history: History
  match: match<Params>
  userInfo: USERINFO
  location: Location
}

// 初始化案件数据
async function initData (caseid: string, h: History, userInfo: USERINFO, setCaseDetail: (v: CaseSchema) => void, setUsed: (v: OperateItem[]) => void, setUnuse: (v: OperateItem[]) => void, setCourtItems: (v: TribunalPartySchema[]) => void): Promise<void> {
  const hide = message.loading('加载中', 120)
  const o = await getOperate(caseid, setUsed, setUnuse)
  const [e, d] = await awaitWrap(getCaseDetailRequest(caseid))
  hide()
  if (e !== null) {
    return undefined
  } else if (d === null) {
    return await message.error('数据未返回')
  }
  setCaseDetail(d)
  o.used.map(li => li.key)
}

// 获取操作列表
async function getOperate (caseid: string, setUsed: (v: OperateItem[]) => void, setUnuse: (v: OperateItem[]) => void): Promise<{ used: OperateItem[], unuse: OperateItem[] }> {
  const [e, d] = await awaitWrap(getOperateRequest(caseid))
  if (e === null && d !== null) {
    setUsed(d.used)
    setUnuse(d.unuse)
    return d
  }
  return {
    used: [],
    unuse: []
  }
}

// 更新操作
async function addOperate (caseid: string, operate: string): Promise<void> {
  await awaitWrap(addOperateUsedRequest(caseid, operate))
}

// 根据类型初始化任务
async function initTaskByType (caseid: string, t: string, setTask: (v: TaskSchema | null) => void, setOperatorids: (v: string[]) => void, setClosureSubmitids: (v: string[]) => void): Promise<void> {
  const [e, d] = await awaitWrap(initTaskByTypeRequest(caseid, t))
  if (e === null && d !== null) {
    setOperatorids(d.operatorids)
    setClosureSubmitids(d.submitOptids)
    if (d.task instanceof Array && d.task.length > 0) {
      setTask(d.task[0])
    }
  }
}

function Main ({ history, match, userInfo, location }: Props): ReactElement {
  const [caseDetail, setCaseDetail] = useState<CaseSchema | null>(null)
  const [used, setUsed] = useState<OperateItem[]>([])
  const [unuse, setUnuse] = useState<OperateItem[]>([])
  const [courtItems, setCourtItems] = useState<TribunalPartySchema[]>([])
  const [closureTask, setClosureTask] = useState<TaskSchema | null>(null)
  const [closureOperatorids, setClosureOperatorids] = useState<string[]>([])
  const [closureSubmitids, setClosureSubmitids] = useState<string[]>([])
  const [recordTask, setRecordTask] = useState<TaskSchema | null>(null)
  const [recordOperatorids, setRecordOperatorids] = useState<string[]>([])
  const [recordSubmitids, setRecordSubmitids] = useState<string[]>([])
  const refreshCase = function (): void {
    initData(match.params.caseid, history, userInfo, setCaseDetail, setUsed, setUnuse, setCourtItems).catch(e => console.error(e))
  }
  useEffect(() => {
    if (match !== null) {
      refreshCase()
    }
  }, [])
  useEffect(() => {
    if (caseDetail !== null) {
      initTaskByType(caseDetail.id, '结案文书', setClosureTask, setClosureOperatorids, setClosureSubmitids).then(null, null)
      initTaskByType(caseDetail.id, '庭审笔录', setRecordTask, setRecordOperatorids, setRecordSubmitids).then(null, null)
    }
  }, [caseDetail])
  if (caseDetail === null) {
    return (
      <div className={style.loading}><Skeleton active /></div>
    )
  }
  const initValues = {
    courtItems,
    setCourtItems,
    usedOperates: used,
    closureTask,
    closureOperatorids,
    closureSubmitids,
    recordTask,
    recordOperatorids,
    recordSubmitids
  }
  return (
    <Context.Provider value={initValues}>
      <div className={style.container}>
        <div className={style.body}>
          <BerifInfo data={caseDetail} />
          <Switch location={location}>
            <Route exact path='/admin/case-detail/:caseid/closure-document' render={() => <ClosureDocument caseDetail={caseDetail} refreshCase={refreshCase} refreshOpereate={async k => await addOperate(caseDetail.id, k)} />} />
            <Route exact path='/admin/case-detail/:caseid/closure-document/create' render={() => <ClosureDocumentCreate caseDetail={caseDetail} refreshCase={refreshCase} refreshOpereate={async k => await addOperate(caseDetail.id, k)} />} />
            <Route exact path='/admin/case-detail/:caseid/record' render={() => <Record caseDetail={caseDetail} refreshCase={refreshCase} refreshOpereate={async k => await addOperate(caseDetail.id, k)} />} />
            <Route exact path='/admin/case-detail/:caseid/record/create' render={() => <RecordCreate caseDetail={caseDetail} refreshCase={refreshCase} refreshOpereate={async k => await addOperate(caseDetail.id, k)} />} />
            <Route exact path='/admin/case-detail/:caseid' render={() => <Info caseDetail={caseDetail} used={used} />} />
            <Route path='/admin/case-detail/:caseid/*' render={({ match }) => <Redirect to={`/admin/case-detail/${match.params.caseid}`} />} />
          </Switch>
        </div>
        <Switch location={location}>
          <Route exact path='/admin/case-detail/:caseid' render={({ history, match }) => <Operate data={caseDetail} unuse={unuse} used={used} refreshOpereate={async k => { await addOperate(caseDetail.id, k); await initData(match.params.caseid, history, userInfo, setCaseDetail, setUsed, setUnuse, setCourtItems) }} />} />
        </Switch>
        <BackTop>
          <span className={style.backtop}><ToTopOutlined /></span>
        </BackTop>
      </div>
    </Context.Provider>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {

  }
})(Main)
