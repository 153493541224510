import React from 'react'
import { TribunalPartySchema } from '@src/components/Schema/TribunalPartySchema'
import { OperateItem } from './ajax'
import { TaskSchema } from '@components/Schema/TaskSchema'

interface InitProps {
  courtItems: TribunalPartySchema[]
  setCourtItems: (v: TribunalPartySchema[]) => void
  usedOperates: OperateItem[]
  closureTask: TaskSchema | null
  closureOperatorids: string[]
  closureSubmitids: string[]
  recordTask: TaskSchema | null
  recordOperatorids: string[]
  recordSubmitids: string[]
}

export const Context = React.createContext<InitProps>({
  courtItems: [],
  setCourtItems: (v: TribunalPartySchema[]) => {},
  usedOperates: [],
  closureTask: null,
  closureOperatorids: [],
  closureSubmitids: [],
  recordTask: null,
  recordOperatorids: [],
  recordSubmitids: []
})
