import React, { ReactElement, useRef, useEffect, useContext, useState } from 'react'
import { Form, Input, Space, Button, message } from 'antd'
import { History } from 'history'
import { useParams, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import style from './Index.module.less'
import { BASIC_STATE } from '@redux/reducers/basic'
import { USERINFO } from '@redux/actions/basic'
import Upload, { uploadFile } from '@components/Upload/Index'
import { createTask } from './ajax'
import { awaitWrap, scrollToAnchor, confirmAction } from '@js/tool'
import Text from '@src/components/Text'
import { Context } from '../../context'

const { Item } = Form
const { TextArea } = Input

interface Params {
  caseid: string
}

interface Props {
  caseDetail: CaseSchema
  userInfo: USERINFO
  refreshCase: () => void
  refreshOpereate: (k: string) => void
}

// 格式化数据
function formatData (d: { [k: string]: any }, c: CaseSchema, fileid: string, taskid: string): { [k: string]: any } {
  return {
    fileid,
    content: d.content,
    taskid
  }
}

// 提交事件
async function submitHandle (d: { [k: string]: any }, c: CaseSchema, taskid: string, history: History, refreshCase: () => void, refreshOpereate: (v: string) => void): Promise<void> {
  let fileid = ''
  if (d.file instanceof Array) {
    const [e, l] = await awaitWrap(uploadFile(d.file))
    if (e !== null) {
      return undefined
    } else if (l === null) {
      return await message.error('文件id未返回')
    } else {
      fileid = l.map(li => li.id).join()
    }
  }
  const [e] = await awaitWrap(createTask(formatData(d, c, fileid, taskid)))
  if (e !== null) {
    return undefined
  }
  history.push(`/admin/case-detail/${c.id}`)
  refreshCase()
  setTimeout(() => {
    scrollToAnchor('#arbitrator_jiean', history)
  }, 500)
}

function cancelToBack (id: string, h: History): void {
  h.push(`/admin/case-detail/${id}`)
  setTimeout(() => {
    scrollToAnchor('#arbitrator_jiean', h)
  }, 1000)
}

function Main ({ caseDetail, userInfo, refreshCase, refreshOpereate }: Props): ReactElement {
  const history = useHistory()
  const params = useParams<Params>()
  const formRef = useRef(null)
  const c = useContext(Context)
  const [taskid, setTaskid] = useState<string>('')
  useEffect(() => {
    if (c.closureTask === null || !c.closureOperatorids.includes(userInfo.id)) {
      history.push(`/admin/case-detail/${caseDetail.id}`)
    }
  }, [userInfo, c])
  useEffect(() => {
    if (c.closureTask !== null) {
      setTaskid(c.closureTask.id)
    }
  }, [c])
  return (
    <div>
      <div className={style.header}>结案文书</div>
      <div className={style.block}>
        <div className={style['block-title']}>提交结案文书</div>
        <Form ref={formRef} labelCol={{ flex: '80px' }} onFinish={
          (d) => confirmAction('您确定要提交结案文书吗？', () => {
            submitHandle(d, caseDetail, taskid, history, refreshCase, refreshOpereate).catch(e => console.error(e))
          })}>
          <Item label='发起人'>
            <Text value={userInfo.realname} />
          </Item>
          <Item label='结案文书' name='file' rules={[{ required: true, message: '结案文书不能为空' }]}>
            <Upload accept='.docx' length={1} />
          </Item>
          <Item label='备注' name='content'>
            <TextArea autoSize={{ minRows: 5 }} placeholder='请输入' />
          </Item>
          <div className={style['btn-line']}>
            <Space>
              <Button htmlType='button' onClick={() => cancelToBack(params.caseid, history)}>取消提交</Button>
              <Button type='primary' htmlType='submit'>确认提交</Button>
            </Space>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {}
})(Main)
