import { ReactElement, useRef, RefObject, useEffect, useState } from 'react'
import { Form, Input, FormInstance, Button, message, Modal } from 'antd'
import style from './Index.module.less'
import Text from '@components/Text'
import { connect, useSelector } from 'react-redux'
import { BASIC_STATE } from '@src/redux/reducers/basic'
import { getRequest, updateRequest } from './ajax'
import { awaitWrap } from '@src/assets/js/tool'
import { setArbitratorInfo } from '@redux/actions/basic'
import { ArbitratorSchema } from '@components/Schema/ArbitratorInfo'
import { checkIdCard } from '@js/identityTest'
import { RuleObject } from 'antd/lib/form'
import classNames from 'classnames'
import { isMobile } from '@src/assets/js/config'

const { Item } = Form
const { TextArea } = Input

async function initData (id: string, formRef: RefObject<FormInstance>, setArbitratorInfo: (v: ArbitratorSchema) => void, setReady: (v: boolean) => void): Promise<void> {
  const [e, d] = await awaitWrap(getRequest(id))
  if (e !== null) {
    message.warning(e.message).then(null, null)
    return undefined
  }
  const c = formRef.current
  setReady(true)
  if (d !== null && c !== null) {
    setArbitratorInfo(d)
    c.setFieldsValue(d)
  }
}

async function onFinish (id: string, d: { [k: string]: any }): Promise<void> {
  const [e] = await awaitWrap(updateRequest(id, d))
  if (e !== null) {
    message.warning(e.message).then(null, null)
    return undefined
  }
  message.success('更新成功').then(null, null)
}

// 验证身份证
async function validateLicense (_: RuleObject, value: string): Promise<any> {
  if (typeof value === 'string' && value.length > 0) {
    const e = checkIdCard.test(value)
    if (e !== null && e !== '') {
      throw new Error(e)
    }
  }
  return await Promise.resolve()
}

async function validateBank (_: RuleObject, value: string): Promise<any> {
  if (typeof value === 'string' && value.length > 0 && !/^\d{16,19}$/.test(value)) {
    throw new Error('银行卡号请输入16到19位数字（不带空格）')
  }
  return await Promise.resolve()
}

async function validateBank2 (_: RuleObject, value: string): Promise<any> {
  if (typeof value === 'string' && value.length > 0 && !/^(\d{16}|\d{19})$/.test(value)) {
    throw new Error('银行卡号请输入16或19位数字（不带空格）')
  }
  return await Promise.resolve()
}

interface Props {
  arbitratorInfo: ArbitratorSchema | null
  setArbitratorInfo: (v: ArbitratorSchema) => void
}

function Main ({ setArbitratorInfo, arbitratorInfo }: Props): ReactElement {
  const formRef = useRef<FormInstance>(null)
  const [ready, setReady] = useState(false)
  const [visible, setVisible] = useState(false)
  const userInfo = useSelector((state: { basic: BASIC_STATE }) => {
    return state.basic.userInfo
  })
  useEffect(() => {
    initData(userInfo.id, formRef, setArbitratorInfo, setReady).catch(e => console.error(e))
  }, [userInfo])
  useEffect(() => {
    if (ready && arbitratorInfo !== null) {
      const i = arbitratorInfo
      const hasBank = typeof i.bank2 === 'string' && i.bank2.length > 0
      const hasBankCode = typeof i.bank2_accountno === 'string' && i.bank2_accountno.length > 0
      setVisible(!hasBank || !hasBankCode)
    }
  }, [arbitratorInfo])
  return (
    <div className={classNames(style.container, { [style.mobile]: isMobile })}>
      <Modal title='通知公告' visible={visible && ready} onCancel={() => { setVisible(false) }} footer={(<Button type='primary' onClick={() => setVisible(false)}>确定</Button>)}>
        <p className={style.notice}>根据<a href='https://www.wuhu.gov.cn/openness/public/6596381/33902561.html' target='_blank'>《关于芜湖市市本级预算单位变更零余额账户的通知》财库〔2022〕91号文件</a>，我单位开户行由徽商银行北京路变更为工商银行赭山支行 ，烦请各仲裁员登录芜湖仲裁系统进行个人工商银行卡号信息的填写。感谢配合！</p>
      </Modal>
      <div className={style.header}>个人信息维护</div>
      <div className={style.body}>
        <Form ref={formRef} className={style.form} labelCol={{ flex: '128px' }} labelAlign='left' colon={false} onFinish={async d => await onFinish(userInfo.id, d)}>
          <div className={style.label}>
            <span>基本信息</span>
          </div>
          <div className={style.block}>
            <Item name='mobile' label='手机号码' required>
              <Text />
            </Item>
            <Item name='license' label='身份证号' rules={[{ required: true, message: '身份证号不能为空' }, { validator: validateLicense }]}>
              <Input className={style['long-input']} placeholder='请输入身份证号' maxLength={18} />
            </Item>
            <Item name='tel' label='固定电话'>
              <Input className={style['long-input']} placeholder='请输入固定电话' />
            </Item>
            <Item name='structure_address' label='收件地址' rules={[{ required: true, message: '收件地址不能为空' }]}>
              <TextArea className={style['long-input']} placeholder='请输入收件地址' rows={3} />
            </Item>
            <Item name='structure' label='所在单位' rules={[{ required: true, message: '所在单位不能为空' }]}>
              <Input className={style['long-input']} placeholder='退休人员请填写退休' />
            </Item>
            <Item name='structure_tel' label='单位电话'>
              <Input className={style['long-input']} placeholder='请输入单位电话' />
            </Item>
          </div>
          <div className={style.label}>
            <span>微商银行</span>
          </div>
          <div className={style.block}>
            <Item name='bank' label='开户行'>
              <Input className={style['long-input']} placeholder='请输入开户行' />
            </Item>
            <Item name='bank_accountno' label='银行卡号' rules={[{ validator: validateBank }]}>
              <Input className={style['long-input']} placeholder='请输入银行卡号' maxLength={19} />
            </Item>
          </div>
          <div className={style.label}>
            <span>工商银行</span>
          </div>
          <div className={style.block}>
            <Item name='bank2' label='开户行' rules={[{ required: true, message: '开户行不能为空' }]}>
              <Input className={style['long-input']} placeholder='请输入开户行' />
            </Item>
            <Item name='bank2_accountno' label='银行卡号' rules={[{ required: true, message: '银行卡号不能为空' }, { validator: validateBank2 }]}>
              <Input className={style['long-input']} placeholder='请输入银行卡号' maxLength={19} />
            </Item>
          </div>
          <div className={style['btn-line']}>
            <Button type='primary' htmlType='submit' block={isMobile}>确定</Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo,
    arbitratorInfo: state.basic.arbitratorInfo
  }
}, dispatch => {
  return {
    setArbitratorInfo: (v: ArbitratorSchema) => dispatch(setArbitratorInfo(v))
  }
})(Main)
