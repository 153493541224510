import { ReactElement } from 'react'
import { List } from 'antd'
import style from './Index.module.less'
import { useHistory } from 'react-router-dom'

export interface ItemSchema {
  hearingroom_name: string
  date: string
  time: string
  weekday: string
  caseno_desc: string
  operator: string
  arbitrators: string
  caseid: string
  [k: string]: any
}

interface Props {
  data: ItemSchema
  isNext: boolean
}
function Main ({ data, isNext }: Props): ReactElement {
  const s = [style.card]
  const history = useHistory()
  if (isNext) {
    s.push(style.next)
  }
  return (
    <List.Item>
      <div className={s.join(' ')} onClick={() => {
        if (typeof data.meetingid === 'string' && data.meetingid.length > 0) {
          history.push(`/admin/meeting-detail/${data.meetingid}`)
        } else {
          history.push(`/admin/case-detail/${data.caseid}`)
        }
      }}>
        <div className={style['card-header']}>
          <div className={style['card-header-time']}><span>{data.date}日</span></div>
          <div className={style['card-header-title']}>
            <span>{data.hearingroom_name}</span>
            <span>{data.time}</span>
          </div>
        </div>
        <div className={style['card-body']}>
          <div className={style['card-body-time']}><div>周</div><div>{data.weekday}</div></div>
          <div className={style['card-body-content']}>
            <div className={style['card-line']}>
              <span>案件编号</span>
              <span>{data.caseno_desc}</span>
            </div>
            <div className={style['card-line']}>
              <span>办案秘书</span>
              <span>{data.operator}</span>
            </div>
            <div className={style['card-line']}>
              <span>开庭人员</span>
              <span>{data.arbitrators}</span>
            </div>
          </div>
        </div>
      </div>
    </List.Item>
  )
}

export default Main
