// import { BASIC_ACTION } from '../actions/basic'
import BASIC from '../actionTypes/basic'
import { USERINFO } from '../actions/basic'
import { AnyAction } from 'redux'
import { ArbitratorSchema } from '@components/Schema/ArbitratorInfo'

export interface BASIC_STATE {
  token: NonNullable<string>
  userInfo: NonNullable<USERINFO>
  SMSSendTime: number
  arbitratorInfo: ArbitratorSchema | null
}
const initState = {
  token: '',
  userInfo: {
    app_default: '',
    desktop: '',
    employeeid: '',
    id: '',
    is_auth: 0,
    mobile: '',
    realname: '',
    role_default: ''
  },
  SMSSendTime: 0,
  arbitratorInfo: null
}

// 处理并返回 state
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state: BASIC_STATE = initState, action: AnyAction): BASIC_STATE => {
  switch (action.type) {
    case BASIC.SET_TOKEN:
      return { ...state, token: action.data }
    case BASIC.SET_USER_INFO:
      return { ...state, userInfo: action.data }
    case BASIC.SET_SMS_SEND_TIME:
      return { ...state, SMSSendTime: action.data }
    case BASIC.SET_ARBITRATOR_INFO:
      return { ...state, arbitratorInfo: action.data }
    default:
      return state
  }
}
