import { $post } from '@js/request'

// 创建任务
export async function createTask (data: { [k: string]: any }): Promise<void> {
  const res = await $post('/api/arbitrator/task/feedback', data)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
