import { ReactElement } from 'react'
import style from './Index.module.less'
import { MonthRemuneration, RemunerationSchema } from '@components/Schema/RemunerationSchema'
import { Space, Divider, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table/Table'
import { formatDate, formatMoney } from '@js/tool'

interface Props {
  data: MonthRemuneration
}

const column: ColumnsType<RemunerationSchema> = [
  {
    title: '案件编号',
    dataIndex: 'caseno_desc',
    key: 'caseno_desc'
  },
  {
    title: '审批时间',
    dataIndex: 'pay_datetime',
    key: 'pay_datetime',
    render: (v) => {
      if (typeof v === 'number') {
        return formatDate(v)
      }
    }
  },
  {
    title: '计提报酬',
    dataIndex: 'real_amount',
    key: 'real_amount',
    render: (v) => {
      if (typeof v === 'number') {
        return formatMoney(v)
      }
    }
  },
  {
    title: '代扣个税',
    dataIndex: 'tax',
    key: 'tax',
    render: (v) => {
      if (typeof v === 'number') {
        return formatMoney(v)
      }
    }
  },
  {
    title: '报酬费用',
    dataIndex: 'real_amount_withouttax',
    key: 'real_amount_withouttax',
    render: (v) => {
      if (typeof v === 'number') {
        return formatMoney(v)
      }
    }
  }
]

function Main ({ data }: Props): ReactElement {
  return (
    <div className={style.container}>
      <div className={style['header-line']}>
        <Space size='middle' split={<Divider type='vertical' />}>
          <span className={style['header-date']}>{data.date}</span>
          <span className={style['header-money']}>{formatMoney(data.amount)} 报酬费用</span>
        </Space>
      </div>
      <div className={style.table}>
        <Table rowKey='id' dataSource={data.list} columns={column} pagination={false} />
      </div>
    </div>
  )
}

export default Main
