import { $get } from '@js/request'
import { FileSchema } from '@components/Schema/FileSchema'

export async function getDocmentUrl (caseid: string): Promise<FileSchema> {
  const res = await $get('/api/arbitrator/file/getDocByBizType', {
    caseid,
    biztype: '庭审笔录'
  })
  return res.data
}
