import { $get, $put, $post } from '@js/request'
import _ from 'lodash'
import { CaseSchema } from '@components/Schema/CaseSchema'
import { USERINFO } from '@src/redux/actions/basic'
import { TaskSchema } from '@components/Schema/TaskSchema'
import { formatCaseDataLicense } from '@src/assets/js/tool'

// 获取案件详情请求请求
export async function getCaseDetailRequest (caseid: string): Promise<CaseSchema> {
  const res = await $get(`/api/arbitrator/case/${caseid}`)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  formatCaseDataLicense(res.data)
  return res.data
}

export interface SubData { [v: string]: any }

// 更新案件数据
export async function updateCaseRequest (caseDetail: CaseSchema, modifyData: SubData, userInfo: USERINFO): Promise<void> {
  const d = _.cloneDeep(caseDetail)
  const id = d.id
  d.claim.items.forEach(li => {
    delete li.id
  })
  const o: SubData = {
    claim: d.claim,
    submittal: userInfo.realname,
    submittalid: userInfo.id,
    submit_datetime: Math.floor(Date.now() / 1000),
    basis: d.basis,
    cause_action: d.cause_action,
    status: d.status,
    basis_fileid: d.basis_fileid,
    ...modifyData
  }

  const res = await $put(`/api/arbitrator/case/${id}`, o)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

export interface OperateItem {
  flow_name: string
  key: string
  orderno: number
  title: string
}

export interface OperateConfig {
  unuse: OperateItem[]
  used: OperateItem[]
}

// 获取操作列表
export async function getOperateRequest (caseid: string): Promise<OperateConfig> {
  const res = await $get('/api/arbitrator/caseoperate/arbitratorOperate', {
    caseid
  })
  return res.data
}

// 更新操作
export async function addOperateUsedRequest (caseid: string, operate: string): Promise<void> {
  const res = await $post('/api/arbitrator/caseoperate', {
    caseid,
    operate
  })
  return res.data
}

// 根据类型获取任务
export async function initTaskByTypeRequest (caseid: string, t: string): Promise<{ submitOptids: string[], operatorids: [], task: TaskSchema | null }> {
  const res = await $get('/api/arbitrator/task', {
    caseid,
    type: t
  })
  return res.data
}
