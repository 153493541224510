// import React from 'react'
// import { Redirect } from 'react-router-dom'
import Admin from '@src/pages/Admin/Index'
import Login from '@src/pages/Login/Index'
import { routerConfigs } from './registerRouter'

interface Page {
  path: string
  component: React.FC<any>
  auth: boolean
  checkeAuth?: boolean
}

export const RouterConfig: Page[] = [
  {
    path: '/admin*',
    component: Admin,
    auth: true,
    checkeAuth: true
  },
  {
    path: '/login',
    component: Login,
    auth: false
  },
  ...routerConfigs
]
