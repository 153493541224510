import { CaseSchema } from '@src/components/Schema/CaseSchema'
import React, { ReactElement } from 'react'
import ClosureDocument from '../ClosureDocument/Index'
import Record from '../Record/Index'

export interface OperateItem {
  flow_name: string
  key: string
  orderno: number
  title: string
}

interface Props {
  data: CaseSchema
  list: OperateItem[]
}

function Main ({ data, list = [] }: Props): ReactElement {
  const keys = list.map(li => li.key)
  return (
    <div>
      {keys.includes('arbitrator_bilu') ? <Record data={data} /> : <span />}
      {keys.includes('arbitrator_jiean') ? <ClosureDocument data={data} /> : <span />}
    </div>
  )
}

export default Main
