import { ReactElement, useState, useEffect } from 'react'
import style from './Index.module.less'
import { Spin, message } from 'antd'
import { ItemSchema } from './components/Item/Index'
import moment from 'moment'
import { CountBookSchema } from '@components/Schema/CountBookSchema'
import { awaitWrap } from '@js/tool'
import { getList } from './ajax'
import CurrentList from './components/CurrentList/Index'
import NextList from './components/NextList/Index'

async function initList (
  currentMonth: string,
  nextMonth: string,
  setCurrentData: (v: ItemSchema[]) => void,
  setNextData: (v: ItemSchema[]) => void,
  setLoading: (v: boolean) => void
): Promise<void> {
  setLoading(true)
  const [e, d] = await awaitWrap(getList())
  setLoading(false)
  if (e !== null) {
    message.warning(e.message).then(null, null)
    return undefined
  }
  if (d instanceof Array) {
    formatList(d, currentMonth, nextMonth, setCurrentData, setNextData)
  }
}

function formatList (
  list: CountBookSchema[],
  currentMonth: string,
  nextMonth: string,
  setCurrentData: (v: ItemSchema[]) => void,
  setNextData: (v: ItemSchema[]) => void
): void {
  list.sort((a, b) => a.begin_datetime - b.begin_datetime)
  const arr: ItemSchema[] = []
  list.forEach((li) => {
    const o: ItemSchema = {
      time: '',
      weekday: '',
      date: '',
      month: '',
      ...li
    }
    const beginTime = moment(li.begin_datetime * 1000)
    const begin = beginTime.format('HH:mm')
    const end = moment(li.end_datetime * 1000).format('HH:mm')
    const weekday = beginTime.day()
    o.time = `${begin}~${end}`
    o.weekday = ['日', '一', '二', '三', '四', '五', '六'][weekday]
    o.date = beginTime.format('D')
    o.month = beginTime.format('MM')
    arr.push(o)
  })
  const current = arr.filter((li) => li.month === currentMonth)
  const next = arr.filter((li) => li.month === nextMonth)
  setCurrentData(current)
  setNextData(next)
}

// 格式化当前日期
function formatCurrentAndNextDate (
  setCurrentYear: (v: string) => void,
  setCurrentMonth: (v: string) => void,
  setNextYear: (v: string) => void,
  setNextMonth: (v: string) => void
): void {
  const d = moment()
  const currentYear = d.format('YYYY')
  const currentMonth = d.format('MM')
  d.add(1, 'M')
  const nextYear = d.format('YYYY')
  const nextMonth = d.format('MM')
  setCurrentYear(currentYear)
  setCurrentMonth(currentMonth)
  setNextYear(nextYear)
  setNextMonth(nextMonth)
}

function Main (): ReactElement {
  const [loading, setLoading] = useState(false)
  const [currentData, setCurrentData] = useState<ItemSchema[]>([])
  const [nextData, setNextData] = useState<ItemSchema[]>([])
  const [currentYear, setCurrentYear] = useState('')
  const [currentMonth, setCurrentMonth] = useState('')
  const [nextYear, setNextYear] = useState('')
  const [nextMonth, setNextMonth] = useState('')
  useEffect(() => {
    formatCurrentAndNextDate(
      setCurrentYear,
      setCurrentMonth,
      setNextYear,
      setNextMonth
    )
  }, [])
  useEffect(() => {
    if (currentMonth !== '') {
      initList(
        currentMonth,
        nextMonth,
        setCurrentData,
        setNextData,
        setLoading
      ).then(null, null)
    }
  }, [currentMonth])
  return (
    <div className={style.container}>
      {loading && (
        <div className={style.loading}>
          <Spin />
        </div>
      )}
      <CurrentList data={currentData} loading={loading} year={currentYear} month={currentMonth} />
      <NextList data={nextData} loading={loading} year={nextYear} month={nextMonth} />
    </div>
  )
}

export default Main
