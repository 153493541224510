import React, { useReducer } from 'react'
import style from './Index.module.less'
import bgImg from '@imgs/logoBg.png'
import logoImg from '@imgs/logo.png'
import LoginForm from './components/LoginForm'
import { reducer, initialState } from './reducer'
import { companyName, appName, companyUrl, version, isMobile } from '@js/config'
import { RouteComponentProps } from 'react-router-dom'
import { Space } from 'antd'
import classNames from 'classnames'

function Main ({ history }: RouteComponentProps): React.ReactElement {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <div className={classNames(style.container, { [style.mobile]: isMobile })}>
      <div className={style['body-right']}>
        <img src={bgImg} />
      </div>
      <div className={style.content}>
        <div className={style['logo-line']}>
          <a href={`http://${companyUrl}`} className={style['logo-line-inner']}>
            <img className={style['logo-img']} src={logoImg} />
            <span>{companyName}</span>
            <span className={style['logo-point']} />
            <span>{appName}</span>
          </a>
        </div>
        <div className={style['form-container']}>
          <LoginForm state={state} dispatch={dispatch} history={history} />
        </div>
      </div>
      <div className={style.foot}>
        <Space>
          <span>{companyName}</span>
          <span>{companyUrl}</span>
          <span>{version}</span>
        </Space>
      </div>
    </div>
  )
}

export default Main
